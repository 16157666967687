<template>
    <header class="header">

        <!-- navbar for pc -->

        <nav class="navbar ">

            <div class="navbar__container">

                <section class="navbar__start">

                    <button class="button navbar__button d-flex" @click="toggleSideWindow()" @mouseleave="mouseLeaveHamberger()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
                            <defs:style>
                                .a {
                                    fill: #002036;
                                }
                            </defs:style>
                            <path class="a"
                                  d="M29.8,80.427H.4a.4.4,0,0,1-.4-.4V78.4A.4.4,0,0,1,.4,78H29.8a.4.4,0,0,1,.4.4v1.618A.4.4,0,0,1,29.8,80.427Zm0,10.787H.4a.4.4,0,0,1-.4-.4V89.191a.4.4,0,0,1,.4-.4H29.8a.4.4,0,0,1,.4.4v1.618A.4.4,0,0,1,29.8,91.214ZM29.8,102H.4a.4.4,0,0,1-.4-.4V99.978a.4.4,0,0,1,.4-.4H29.8a.4.4,0,0,1,.4.4V101.6A.4.4,0,0,1,29.8,102Z"
                                  transform="translate(0 -78)"/>
                        </svg>
                    </button>

                    <div class="logo navbar__logo">
                        <router-link to="/" tag="a" class="link d-flex" exact>
                            <img class="img" :src="currentLogo" alt="">
                        </router-link>
                    </div>

                    <!-- <div class="navbar__search">
                        <input class="input navbar__search-input" type="search" placeholder="חיפוש">
                        <div class="navbar__loupe"></div>
                    </div> -->

                </section>

                <section class="menu navbar__menu">

                    <ul class="menu__list">
                        <router-link v-if="hasPathAccess('/')" to="/"
                                     v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item"
                                :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("ASIDE.MAIN") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/tourism')" to="/tourism"
                                     v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item"
                                :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("ASIDE.TOURISM") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/communication')" to="/communication"
                                     v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item"
                                :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("ASIDE.COMMUNICATION") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/payment')" to="/payment"
                                     v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item"
                                :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("ASIDE.PAYMENTS") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/gift-card')" to="/gift-card"
                                     v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item"
                                :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link space-nowrap">{{ $t("ASIDE.GIFT_CARD") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/game')" to="/game"
                                     v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item"
                                :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("DASHBOARD.GAMES") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/products')" to="/products"
                                     v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item"
                                :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("ASIDE.PRODUCTS") }}</a>

                            </li>

                        </router-link>

                    </ul>

                </section>

            </div>

            <div class="navbar__wrapper">
                <section class='checkout-container cursor-pointer' v-show="cartTotalQuantity">
                    <router-link  to="/cart">
                        <img class="" src="/assets/img/shopping-cart-light.svg" alt="">
                        <span>Checkout</span>
                    </router-link>

                    <div class="counter">{{cartTotalQuantity}}</div>
                </section>

                <section class="select-lang">
                    <vue-select
                        :options="language.options"
                        class="input cus-select"
                        v-model="language.selected"
                        @input="changeLang()"
                    ></vue-select>
                </section>

                <div class="link navbar__support" @click="openSupportModal">
                    {{ $t("AUTHFOOTER.SUPPORT") }}
                </div>

                <section class="profile ">

                    <div class="profile__info">

                        <div class="profile__icon">
                            <img class="" src="/assets/img/user.svg" alt="">
                            <!-- <span class="profile__messege-count">2</span> -->
                        </div>
                        <p class="text profile__client-name">
                            {{ user_details && user_details.businessName ? user_details.businessName : '' }}
                        </p>

                    </div>

                    <div class="profile__more-info">

                        <ul class="profile__list">

                            <li class="profile__item">
                                <p class="text profile__client-name profile__client-name_opacity">
                                    {{ user_details && user_details.businessName ? user_details.businessName : '' }}
                                </p>
                                <p class="text profile__client-number">
                                    {{ $t("HEADER.CUSTOMER_NUMBER") }} {{ user_details && user_details.business_identifier ?
                                    user_details.business_identifier : '' }}
                                </p>
                            </li>

                            <!-- <li class="profile__item">
                                <a class="link profile__link profile__link-messege" href="">
                                    <span class="mobile-navbar__text">
                                        {{ $t("HEADER.UPDATES_ANNOUNCEMENTS") }}
                                    </span>
                                    <span class="profile__messege-amount">2</span>
                                </a>
                            </li> -->

                            <li class="profile__item">
                                <a class="link profile__link" href="">
                                   {{ $t("HEADER.PERSONAL_AREA") }} 
                                </a>
                            </li>
                            <li class="profile__item">
                                <a 
                                    class="link profile__link shortcut-shaniv d-flex align-items-center" 
                                    href="/assets/download/טאצ.exe"
                                    download
                                >
                                    <img src="/new-assets/img/shaniv-logo.png" alt="">
                                   {{ $t("HEADER.SHORTCUT_SHANIV") }} 
                                </a>
                            </li>
                            <li class="profile__item">
                                <a 
                                    class="link profile__link shortcut-shaniv d-flex align-items-center color-grey2" 
                                    href="/assets/download/פלאפייד.exe"
                                    download
                                >
                                    <img src="/assets/img/logo/logo-mobile.png" alt="">
                                   {{ $t("HEADER.SHORTCUT_SHORTCUT") }} 
                                </a>
                            </li>
                            <li v-if="deferredPrompt" class="profile__item">
                                <a
                                    class="link profile__link shortcut-shaniv d-flex align-items-center color-grey2 cursor-pointer add-to-home-screen"
                                >
                                    <img src="/assets/img/logo/logo-mobile.png" alt="">
                                   {{ $t("HEADER.CREATE_SHORTCUT") }}
                                </a>
                            </li>

                            <li class="profile__item">
                                <a href="/logout" class="button profile__exit">
                                    {{ $t("HEADER.LOG_OFF") }} 
                                </a>
                            </li>

                        </ul>

                    </div>

                </section>

                <section v-if="user_balance" class="balance ">

                    <div v-if="balanceCreditBalanceData" class="balance__info">
                        <p class="text balance__caption">
                            {{ balanceCreditBalanceLabelFormatted }}
                        </p>
                        <div class="">
                            <b class="balance__money-amount">{{ balanceCreditBalanceValueFormatted }}</b>
                            <b class="balance__current">{{ $t("COMMON.NIS") }}</b>
                        </div>
                    </div>

                    <div class="balance__more-info">

                        <ul class="balance__list">

                            <li v-if="balanceCreditBalanceData" class="balance__item">
                                <span class="balance__text">
                                    {{ balanceCreditBalanceLabelFormatted }}
                                </span>
                                <b class="frame-amount">{{ balanceCreditBalanceValueFormatted }}</b>
                            </li>

                            <li v-if="balanceFrameData" class="balance__item">
                                <span class="balance__text">
                                    {{ balanceFrameLabelFormatted }}
                                </span>
                                <b class="used-amount">{{ balanceFrameValueFormatted }}</b>
                            </li>

                            <li v-if="balanceBalanceData" class="balance__item">
                                <span class="balance__text">
                                    {{ balanceBalanceLabelFormatted }}
                                </span>
                                <b class="left-amount">{{ balanceBalanceValueFormatted }}</b>
                            </li>

                            <li v-if="balancePointsBalanceData" class="balance__item">
                                <span class="balance__text">
                                    {{ balancePointsBalanceLabelFormatted }}
                                </span>
                                <b class="points-amount">{{ balancePointsBalanceValueFormatted }}</b>
                            </li>

                        </ul>

                    </div>

                </section>

            </div>

        </nav>

        <!-- navbar for mobile -->

        <nav class="navbar-mobile">

            <section class="navbar-mobile__panel">

                <button class="button navbar-mobile__menu-button">

                </button>

                <div class="logo navbar-mobile__logo">
                    <router-link
                        :to="{
                            name: 'Dashboard',
                        }" 
                        class="link d-flex justify-content-center"
                    >
                        <img class="img" src="/assets/img/logo/logo-mobile.png" alt="">
                    </router-link>
                </div>

                <button class="button navbar-mobile__profile-button">
                    <div class="navbar-mobile__user">
                        <img src="/assets/img/user.svg" alt="">
                    </div>
                </button>
                <template v-if="cartTotalQuantity != 0">
                    <router-link
                        :to="{ name: 'CartSystem' }"
                        class="cart-icon-block"
                    >
                        <img src="/assets/img/icons/shopping-cart-light.svg" alt="">
                        <div class="cart-number df-c">
                            {{cartTotalQuantity}}
                        </div>
                    </router-link>
                </template>

            </section>

            <section class="profile-mobile navbar-mobile__profile-mobile">

                <div class="profile-mobile__profile">

                    <div class="profile-mobile__icon">
                        <img src="/assets/img/user.svg" alt="">
                    </div>

                    <div>
                        <p class="text profile-mobile__client-name">
                            {{ user_details && user_details.businessName ? user_details.businessName : '' }}
                        </p>
                        <p class="text profile-mobile__client-number">
                            {{ $t("HEADER.CUSTOMER_NUMBER") }} {{ user_details && user_details.business_identifier ?
                            user_details.business_identifier : '' }}
                        </p>
                    </div>

                </div>

                <ul class="profile-mobile__list">

                    <li v-if="balanceCreditBalanceData" class="profile-mobile__item">
                        <span class="profile-mobile__text">
                            {{ balanceCreditBalanceLabelFormatted }}
                        </span>
                        <b class="frame-amount">{{ balanceCreditBalanceValueFormatted }}</b>
                    </li>
                    <li v-if="balanceFrameData" class="profile-mobile__item">
                        <span class="profile-mobile__text">
                            {{ balanceFrameLabelFormatted }}
                        </span>
                        <b class="used-amount">{{ balanceFrameValueFormatted }}</b>
                    </li>
                    <li v-if="balanceBalanceData" class="profile-mobile__item">
                        <span class="profile-mobile__text">
                            {{ balanceBalanceLabelFormatted }}
                        </span>
                        <b class="left-amount">{{ balanceBalanceValueFormatted }}</b>
                    </li>
                    <li v-if="balancePointsBalanceData" class="profile-mobile__item">
                        <span class="profile-mobile__text">
                            {{ balancePointsBalanceLabelFormatted }}
                        </span>
                        <b class="points-amount">{{ balancePointsBalanceValueFormatted }}</b>
                    </li>
                    <li class="profile-mobile__item">
                        <a class="link profile-mobile__messege" href="">
                            <span class="profile-mobile__text">
                                {{ $t("HEADER.UPDATES_ANNOUNCEMENTS") }}
                            </span>
                            <span class="profile-mobile__messege-amount">2</span>
                        </a>
                    </li>
                    <li class="profile-mobile__item">
                        <a class="link" href="">
                            {{ $t("HEADER.PERSONAL_AREA") }} 
                        </a>
                    </li>
                    <li class="profile-mobile__item" @click="openSupportModal">
                        <a class="link">
                            {{ $t("AUTHFOOTER.SUPPORT") }} 
                        </a>
                    </li>
                    <li v-if="deferredPrompt" class="profile-mobile__item">
                        <a
                            class="link profile__link shortcut-shaniv d-flex align-items-center cursor-pointer add-to-home-screen"
                        >
                            <img src="/assets/img/logo/logo-mobile.png" alt="">
                            {{ $t("HEADER.CREATE_SHORTCUT") }}
                        </a>
                    </li>
                    <li class="profile-mobile__item">
                        <a class="link profile-mobile__messege" href="/cart">
                            <span class="profile-mobile__text">
                                Checkout
                            </span>
                            <span class="profile-mobile__messege-counter" style="background: rgb(71, 232, 183);color: rgb(11, 25, 59);border-radius: 50%;font-size: 14px;max-width: 24px;max-height: 24px;width: 100%;height: auto;position: relative;display: flex;align-items: center;justify-content: center;line-height: 1rem;">{{cartTotalQuantity}}</span>
                        </a>
                    </li>
                    <li class="profile-mobile__item">
                        <router-link to="/logout" tag="button" class="button profile-mobile__button">{{ $t("HEADER.LOG_OFF") }}</router-link>
                    </li>

                </ul>

            </section>

        </nav>
    <SupportPopup></SupportPopup>
    </header>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex'
    import i18nService from "@/common/i18n.service.js";
    import SupportPopup from "@/components/SupportPopup.vue";

    export default {
        name: 'Header',
        props: {
            msg: String
        },
        components: {
            SupportPopup
        },
        data: () => {
            return {
                activeMenuItemClass: 'menu__item_active',
                language: {
                    options: [
                        {
                            code: 'he',
                            label: 'עברית'
                        },
                        {
                            code: 'ar',
                            label: 'العربية'
                        },
                        {
                            code: 'en',
                            label: 'English'
                        },
                    ],
                    selected: {
                        code: 'he',
                        label: 'עברית'
                    }
                }
            }
        },
        methods: {
            ...mapActions('account', ['getUserDetails']),
            ...mapActions("language", {
                changeCurrentLang: "changeCurrentLang",
            }),
            formatNumber(number) {
                let numberFormatObject = new Intl.NumberFormat('en-US')
                return numberFormatObject.format(number)
            },
            toTitleCase(str) {
                return str.replace(/\w\S*/g, function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            },
            toggleSideWindow() {
                setTimeout(() => {
                    if(document.getElementsByClassName('aside')[0].classList.contains("aside_hidden")) {
                        localStorage.setItem("isAside", false);
                        localStorage.setItem("isOpenAside", false);
                        document.getElementsByClassName('blank-aside-js')[0].style.marginRight = 0;
                        document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = 0;
                    } else {
                        localStorage.setItem("isOpenAside", true);
                        if (this.$i18n.locale == "en") {
                            document.getElementsByClassName('blank-aside-js')[0].style.marginRight = 0;
                            document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = '250px';
                        } else {
                            document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = 0;
                            document.getElementsByClassName('blank-aside-js')[0].style.marginRight = '250px';
                        }
                    }
                }, 100);
            },
            mouseLeaveHamberger() {
                setTimeout(() => {
                    if (localStorage.getItem("isAside") == "false") {
                        if (localStorage.getItem("isPin") == "false" && !document.getElementsByClassName('aside')[0].classList.contains("aside_hidden")) {
                            document.getElementById('aside_scroll').classList.add("aside_hidden");
                            document.getElementsByClassName('blank-aside-js')[0].style.marginRight = 0;
                            document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = 0;
                        }
                    }
                }, 3000);
            },
            hideSideMenu() {
                if(document.getElementById('aside_scroll').classList.contains("aside_active")) {
                    document.getElementById('aside_scroll').classList.remove("aside_active");
                    document.getElementsByClassName('navbar-mobile__menu-button')[0].classList.remove("navbar-mobile__menu-button_active");
                }
            },
            changeLang() {
                const langId = this.language.selected.code;
                this.changeCurrentLang({
                    lang: langId
                }).then((res) => {
                    if (res == "success") {
                        this.$i18n.locale = langId;
                        i18nService.setActiveLanguage(langId);
                        if (langId == "en") {
                            document.getElementsByTagName("body")[0].classList.remove("rtl-type");
                            document.getElementsByTagName("body")[0].classList.add("ltr-type");
                        } else {
                            document.getElementsByTagName("body")[0].classList.remove("ltr-type");
                            document.getElementsByTagName("body")[0].classList.add("rtl-type");
                        }
                        window.location.reload();
                    } else {
                        this.setDefaultLang();
                    }
                });
                
            },
            setDefaultLang() {
               this.getUserDetails();
                const current_langId = i18nService.getActiveLanguage();
                this.language.selected = this.language.options.find( (el) => {
                    return el.code == current_langId
                }); 
            },
            openSupportModal() {
                this.$modal.show('supportPopup');
            }
        },
        computed: {
            ...mapState({
                user_details: state => state.account.user_details && state.account.user_details.hasOwnProperty('details') ? state.account.user_details.details : null,
                user_balance: state => state.account.user_details && state.account.user_details.hasOwnProperty('balance') ? state.account.user_details.balance : null,
                productsCounter: (state) => state.cartSystem.productsCounter,
                packageCounter: (state) => state.cartSystem.packageCounter,
                deferredPrompt: state => state.add_to_home_screen.deferredPrompt
            }),
            ...mapGetters('permission', {
                hasPathAccess: 'hasPathAccess'
            }),
            ...mapGetters('cartSystem', {
                cartTotalQuantity: 'cartTotalQuantity'
            }),
            balanceFrameData() {
                return this.user_balance && Array.isArray(this.user_balance) && 0 in this.user_balance ? this.user_balance[0] : null;
            },
            balanceBalanceData() {
                return this.user_balance && Array.isArray(this.user_balance) && 1 in this.user_balance ? this.user_balance[1] : null;
            },
            balanceCreditBalanceData() {
                return this.user_balance && Array.isArray(this.user_balance) && 2 in this.user_balance ? this.user_balance[2] : null;
            },
            balancePointsBalanceData() {
                return this.user_balance && Array.isArray(this.user_balance) && 3 in this.user_balance ? this.user_balance[3] : null;
            },
            balanceFrameValueFormatted() {
                return this.balanceFrameData ? this.formatNumber(this.balanceFrameData.value) : ''
            },
            balanceBalanceValueFormatted() {
                return this.balanceBalanceData ? this.formatNumber(this.balanceBalanceData.value) : ''
            },
            balanceCreditBalanceValueFormatted() {
                return this.balanceCreditBalanceData ? this.formatNumber(this.balanceCreditBalanceData.value) : ''
            },
            balancePointsBalanceValueFormatted() {
                return this.balancePointsBalanceData ? this.formatNumber(this.balancePointsBalanceData.value) : ''
            },
            balanceFrameLabelFormatted() {
                return this.balanceFrameData ? this.toTitleCase(this.balanceFrameData.strRepr) : ''
            },
            balanceBalanceLabelFormatted() {
                return this.balanceBalanceData ? this.toTitleCase(this.balanceBalanceData.strRepr) : ''
            },
            balanceCreditBalanceLabelFormatted() {
                return this.balanceCreditBalanceData ? this.toTitleCase(this.balanceCreditBalanceData.strRepr) : ''
            },
            balancePointsBalanceLabelFormatted() {
                return this.balancePointsBalanceData ? this.toTitleCase(this.balancePointsBalanceData.strRepr) : ''
            },
            counter() {
                return this.productsCounter + this.packageCounter;
            }
        },
        created() {
            this.setDefaultLang();
        },
        mounted() {
            if (this.$i18n.locale == "en") {
                document.getElementsByTagName("body")[0].classList.remove("rtl-type");
                document.getElementsByTagName("body")[0].classList.add("ltr-type");
            } else {
                document.getElementsByTagName("body")[0].classList.remove("ltr-type");
                document.getElementsByTagName("body")[0].classList.add("rtl-type");
            }
        },
        watch: {
            '$route' () {
                this.hideSideMenu();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        z-index: 102;
    }
    .navbar {
        font-size: 16px;
        height: 60px;
        flex-wrap: nowrap;
        .profile__item {
            padding: 5px 14px;
        }
    }
    .navbar__logo {
        height: auto;
        width: 124px;
    }

    .navbar__start {
        width: 180px;
    }

    .navbar__menu {
        max-width: unset;
        margin: 0 20px;
    }
    
    .profile__more-info {
        right: unset;
        left: -63px;
    }

    .navbar-mobile__logo {
        height: 36px;
        width: auto;
        display: flex;
        img {
            width: auto;
            object-fit: contain;
        }
    }

    .navbar__support::before,
    .navbar__support::after {
        top: 50%;
        transform: translateY(-50%);
    }    
    // .menu__list {
    //     font-size: 18px;
    // }
    .profile__client-name {
        font-size: 16px;
    }

    .checkout-container {
        margin-left: 1rem;
        color: white;
        position: relative;

        a {
            background: #234395;
            padding: 0.4rem 1.2rem;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            color: white;
            text-decoration: unset;

            span {
                margin-right: 8px;
            }
        }

        .counter {
            background: #47E8B7;
            color: #0B193B;
            border-radius: 50%;
            position: absolute;
            max-width: 32px;
            max-height: 32px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 100%;
            transform: translate(-50%, -20%);
        }
    }

    .select-lang {
        margin-left: 70px;
        border-left: 2px solid #002036;
        padding-left: 20px;
        width: 140px;
        .cus-select {
            padding: 0 5px;
        }
    }

    @media screen and (min-width: 767px) {
        .mobile-navbar__text {
            max-width: 140px;
        }
    }

    .ltr-type {
        .balance__info {
            border-right: unset;
            padding-right: unset;
            margin-right: unset;
            border-left: 2px solid #002036;
            padding-left: 15px;
            margin-left: 25px;
        }

        .balance__more-info {
            left: -90px;
            &::before {
                left: unset;
                right: 60px;
            }
        }

        .profile__info {
            border-right: unset;
            padding-right: unset;
            margin-right: unset;
            border-left: 2px solid #002036;
            padding-left: 17px;
            margin-left: 13px; 
        }

        .profile__more-info {
            left: unset;
            right: -63px;
        }

        .profile__icon {
            margin-left: unset;
            margin-right: 13px;
        }
           
        .navbar__support::before {
            left: -41px; 
            right: 'unset';
        } 
        .navbar__support::after {
            left: -52px; 
            right: 'unset'
        }
        
        .checkout-container {
            margin-right: 1rem;

            a span {
                margin-left: 8px;
            }

            .counter {
                left: 0;
            }
        }
        .select-lang {
            margin-left: 0;
            margin-right: 70px;
            border-left: unset;
            border-right: 2px solid #002036;
            padding-left: 0;
            padding-right: 20px;
        }
        .cart-icon-block {
            left: unset;
            right: 100px;
        }
    }
    .cart-icon-block {
        position: absolute;
        left: 100px;
        top: 15px;
        img {
            height: 32px;
        }
        .cart-number {
            position: absolute;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            font-size: 13px;
            background-color: #47E8B7;
            top: -5px;
            right: -10px;
        }
    }
    .shortcut-shaniv {
        img {
            height: 30px;
            margin-left: 5px;
        }
    }
    .ltr-type {
        .shortcut-shaniv {
            img {
                margin-right: 5px;
                margin-left: unset;
            }
        }
    }
</style>