var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"cus-asid aside",class:(_vm.isOpenAside=='true')?'':'aside_hidden',attrs:{"id":"aside_scroll"},on:{"mouseleave":_vm.mouseLeave,"mouseenter":_vm.mouseEnter}},[(_vm.isPin)?_c('img',{staticClass:"pin-icon",attrs:{"src":"/assets/img/icons/pin.png","alt":"","id":"pin_icon"},on:{"click":function($event){return _vm.changePin(false)}}}):_c('img',{staticClass:"pin-icon",attrs:{"src":"/assets/img/icons/unpin.png","alt":""},on:{"click":function($event){return _vm.changePin(true)}}}),_c('nav',{staticClass:"aside__navigation"},[_c('ul',{staticClass:"aside__list"},[_vm._l((_vm.allowedMenuItems),function(menu,i){return [(!menu.meta.subAdmins)?_c('li',{key:i,staticClass:"aside__item"},[_c('router-link',{attrs:{"to":menu.page},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"link aside__link",class:[isExactActive && _vm.activeAsideItemClass, 'aside__link-'+ menu.icon],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t(menu.translate)))])]}}],null,true)})],1):_c('li',{key:i,staticClass:"aside__item",class:['aside__item-' + menu.icon ]},[_c('a',{ref:'root' + menu.root,refInFor:true,staticClass:"link aside__link aside__link-plus",class:['aside__link-' + menu.icon],attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t(menu.translate)))]),_c('ul',{ref:'root_ul' + menu.root,refInFor:true,staticClass:"aside__second-lvl",class:'aside__second-lvl' + menu.icon},[_vm._l((menu.submenu),function(smenu,j){return [(!smenu.meta.subAdmins)?_c('li',{key:'s' + j,staticClass:"aside__item-lvl aside__item-second",class:smenu.meta.isLinked ? '' : 'unlinked-item'},[_c('router-link',{attrs:{"to":smenu.page},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [(smenu.meta.isIcon)?_c('a',{staticClass:"link aside__link-lvl d-flex align-items-center",class:[isExactActive && _vm.activeAsideItemClass],attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"mx10",attrs:{"src":smenu.meta.iconSrc,"alt":""}}),_vm._v(" "+_vm._s(_vm.$t(smenu.translate))+" ")]):_c('a',{staticClass:"link aside__link-lvl",class:[isExactActive && _vm.activeAsideItemClass],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t(smenu.translate))+" ")])]}}],null,true)})],1):_c('li',{key:'s' + j,staticClass:"aside__item-lvl aside__item-second"},[_c('a',{ref:'parent' + menu.root + '_' + smenu.root,refInFor:true,staticClass:"link aside__link-lvl aside__link-js aside__link-plus",attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t(smenu.translate)))]),_c('ul',{ref:'parent_ul' + menu.root + '_' + smenu.root,refInFor:true,staticClass:"aside__third-lvl"},_vm._l((smenu.submenu),function(ssmenu,k){return _c('li',{key:'ss' + k,staticClass:"aside__item-third",class:ssmenu.meta.isLinked ? '' : 'unlinked-item'},[_c('router-link',{attrs:{"to":ssmenu.page},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"link aside__link-lvl",class:[isExactActive && _vm.activeAsideItemClass],attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t(ssmenu.translate)))])]}}],null,true)})],1)}),0)])]})],2)])]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }